* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(135deg, #5680E9, #84CEEB, #5AB9EA, #C1C8E4, #8860D0);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
  width: 100%;
  height: 100vh;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
}

.logo-container {
  position: top;
  top: calc(50% - 45px);
}

.searchBox {
  width: 27rem;
  border-radius: 50px;
  padding: 10px 22px;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  margin-top: 45px;
}

#google-img {
  width: 30px;
  cursor: pointer;
}

#mic {
  width: 17px;
  cursor: pointer;
}

#input {
  background: transparent;
  border: 0;
  width: 85%;
  font-size: 20px;
  padding: 5px;
}

#input:focus {
  outline: none;
}

#search-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

@media (max-width: 430px) {
  #input::placeholder {
    padding-left: 13px;
  }
}

ul {
  list-style: none;
}

li {
  padding: 10px 0;
  line-height: 1.2rem;
}

.bold-line {
  display: inline-block;
  border-bottom: 2px solid #000;
  padding-bottom: 2px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #5680E9;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #8860D0;
}

.pagination button.active {
  background-color: #5AB9EA;
}

li {
  padding: 10px 0;
  line-height: 1.2rem;
  margin-bottom: 20px;
}

li div {
  margin-bottom: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}
/* styles.css */
ul li, ul li a, .bold-line {
  color: purple; /* MODIFICATION: Set text color to lavender */
}
